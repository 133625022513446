@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    display: grid
    grid-template-columns: repeat(4, 1fr)

    font-size: 1.5rem

    padding-bottom: 1rem

    div
        text-align: center