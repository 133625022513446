*,
*::after,
*::before
    margin: 0
    padding: 0
    box-sizing: inherit


html
    font-size: 62.5%
    font-family: $font_main
    scroll-behavior: smooth

.hide
    display: none


body
    font-weight: 400
    line-height: 1.7
    color: $black
    overflow-x: hidden
    box-sizing: border-box
    background-color: $white

//span,
//textarea,
//input

// Typography
a
    letter-spacing: 1px
    color: inherit


a,
a:link,
a:active
    text-decoration: none
    z-index: 500


ul, li
    list-style: none

h1, h2, h3
    font-size: 2rem
    font-weight: 400
    letter-spacing: 5px

p
    font-size: 1.5rem

label
    font-size: 1.3rem

input, textarea
    width: 100%
    font-family: $font-main
    font-size: 1.5rem


    outline: none
    border: .1rem solid $black
    border-radius: $radius
    padding: 1.25rem 1rem

    &:focus
        border: .1rem solid $dark_grey

textarea
    resize: vertical
    min-height: 15rem

button
    font-family: $font-main
    font-size: 1.5rem

    outline: none
    border: .1rem solid $black
    border-radius: $radius
    background-color: $white

    padding: 1rem 2.5rem

button, a
    cursor: pointer !important

