@import '../../../../../assets/style/index'

.box
    display: grid
    grid-template-columns: repeat(2, 1fr)

    padding: 1rem 1.25rem
    border: .1rem solid $black
    border-radius: $radius

    position: relative

    overflow: hidden

    > div
        &:nth-child(1)
            border-right: .1rem solid $black

        &:nth-child(2)
            text-align: right

.remove
    width: auto
    height: 100%
    padding: 0 1rem
    position: absolute
    top: 50%
    right: 0
    transform: translateY(-50%)

    background-color: rgba($red, .4)

    display: flex
    justify-content: center
    align-items: center

    color: $white
    font-size: 1.4rem

    cursor: pointer

    &:hover
        background-color: rgba($red, .8)