@import '../../../../../assets/style/index'

.form
    width: 50%
    height: auto
    display: flex


.formGroup
    display: grid
    grid-template-columns: 1fr auto 1fr
    grid-column-gap: 1rem

    border: .1rem solid $black
    border-radius: $radius
    background-color: white

    > div
        width: 100%

        &:nth-child(1)
            margin-top: 0

    > span
        width: .1rem
        height: 100%
        background-color: $black

.formInput
    display: flex
    align-items: center
    > input
        border: none
        background-color: inherit

        &:focus
            border: none

        &:before,
        &:empty
            border-color: blue !important

    &:nth-child(2)
        > input
            text-align: right

.formAction
    padding-left: 1rem

    display: flex
    align-items: center



