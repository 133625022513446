$md: 1024px

@mixin xsm
    @media (max-width: #{640px})
        @content

@mixin sm
    @media (max-width: #{768px})
        @content

@mixin md
    @media (max-width: #{$md})
        @content

@mixin lg
    @media (max-width: #{1280px})
        @content
