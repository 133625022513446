@import '../../assets/style/index'

.box
    width: 100%
    height: auto
    margin: 7.5rem 0

    > ul
        margin-top: 2.5rem

    * li
        font-size: 1.6rem
        border: .1rem solid $black
        border-radius: $radius
        padding: 1rem

        margin-top: 1rem

        &:hover
            cursor: pointer
            color: $red
            border-color: $red

        &:nth-child(1)
            margin-top: 0