@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    > div
        margin-top: $gap

        &:nth-child(1)
            margin-top: 0