@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    > div
        margin-top: 2rem

        &:nth-child(1)
            margin-top: 0

.input

    display: flex
    flex-direction: column
    align-items: flex-start

    > input
        width: 100%



