@import '../../assets/style/index'

.box
    width: 100%
    height: auto

    margin: 7.5rem 0

.form
    width: 100%
    height: auto

    > div
        width: 100%
        margin-top: 2.5rem

        &:nth-child(1)
            margin-top: 0

.action
    display: flex
    justify-content: flex-start
    align-items: center

    > div
        margin-left: 2.5rem
        font-size: 1.2rem
        cursor: pointer

        &:hover
            color: $dark_grey