@import '../../../../assets/style/index'

.box
    width: 100%
    height: 100%

    fill: $dark_grey

    &:hover
        background-color: $dark_grey
        fill: $white

.boxLabel
    width: inherit
    height: inherit
    display: flex
    justify-content: center
    align-items: center

    cursor: pointer

    > span
        $size: 4rem
        width: $size
        height: $size


