@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    display: grid
    grid-template-columns: repeat(2, 1fr)


    > div
        width: 100%

        &:nth-child(1)
            text-align: left

        &:nth-child(2)
            text-align: right
