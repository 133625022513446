@import '../../assets/style/index'

.box
    width: 100%
    height: auto

    padding: 2rem
    border: .1rem solid $black
    border-radius: $radius

    background-color: $white

    > div

        &:nth-child(1)
            text-align: left
            display: flex
            justify-content: space-between
            align-items: center

            > h1
                font-size: 2rem


        &:nth-child(2)
            margin-top: 2.5rem

.icon
    $size: 2.25rem
    width: $size
    height: $size
    fill: $dark-grey

