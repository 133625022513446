@import '../../assets/style/index'

.box
    width: 100%
    height: auto
    margin: 7.5rem auto

    > section
        margin-top: 5rem

        &:nth-child(1)
            margin-top: 0

