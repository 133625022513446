@import '../assets/style/index'

.box
    width: 100%
    height: auto

.boxContent
    width: 100%
    max-width: $standard_max_width
    height: auto

    margin: 0 auto