@import '../../assets/style/index'

.box
    width: 100%
    height: auto

    margin: 7.5rem 0

.form
    > div
        margin-top: 1rem

        &:nth-child(1)
            margin-top: 0

        &:nth-child(2)
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 1rem
