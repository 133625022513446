@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

.boxSpecs
    margin-top: 4rem
    width: 50%

    > div
        margin-top: 1rem