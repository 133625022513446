@import '../../../../../assets/style/index'

$size: 12.5rem

.box
    width: auto
    height: auto

    position: relative


.boxBar
    width: 100%
    height: auto
    background-color: rgba($dark_grey, .4)

    position: absolute
    top: 0
    left: 0
    z-index: 10

    display: flex
    justify-content: center
    align-items: center

    > p
        font-size: 1.2rem
        color: $white

.boxImage
    min-width: $size
    width: 100%
    height: $size
    position: relative
    background-color: $grey

    > img
        position: absolute
        margin: auto
        top: 0
        bottom: 0
        left: 0
        right: 0
        max-width: 100%
        max-height: 100%