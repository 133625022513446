@import '../../assets/style/index'

.box
    width: 100%
    height: auto

    margin: 7.5rem 0

.boxItem
    margin-top: 1rem

    &:nth-child(1)
        margin-top: 0