@import "../../assets/style/index"

.box
    width: 100%
    height: auto

    margin: 7.5rem 0

    * span
        font-size: 1.5rem


.boxContent
    width: 100%

    > div
        //border: .1rem solid $black

        margin-top: 1rem

        &:nth-child(1)
            margin-top: 0