@import '../../../../assets/style/index'

$size: 12rem

.box
    width: 100%
    height: auto




.imageSet
    display: flex
    justify-content: flex-start
    align-items: flex-start
    overflow: auto

    width: 100%
    height: auto


.imageSetBox
    min-width: $size
    width: 100%
    max-width: $size
    height: $size
    background-color: $white

    position: relative

    border: .1rem solid $black
    border-radius: $radius
    overflow: hidden

    margin-left: 1rem

    &:nth-child(1)
        margin-left: 0

    > img
        position: absolute
        margin: auto
        top: 0
        bottom: 0
        left: 0
        right: 0
        max-width: 100%
        max-height: 100%