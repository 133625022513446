// Open Sans
@font-face
    font-family: 'OpenSans'
    src: url('../fonts/OpenSans-Regular.ttf') format("truetype")

// Open Sans SemiBold
@font-face
    font-family: 'OpenSansSemiBold'
    src: url('../fonts/OpenSans-SemiBold.ttf') format("truetype")


$font-main: 'OpenSans'
$font-title: 'OpenSansSemiBold'

//Layout Standard Width
$width: 100%
$standard_max_width: 110rem

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Header bar height
$header_height: 8.5rem

// Colors
$black: #070707
$white: #fbf7f2
$grey: #eeeeee
$dark_grey: #7E7E7E
$black_gradient: #070707
$gold: #e5d3a0
$blue: #627271
$red: red
$orange: orange
$green: green

// Border Radius
$radius: 1rem

// GAP
$gap: 1rem