@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto
    padding: .5rem
    position: absolute
    bottom: 0
    left: 0

    background-color: rgba($red, .4)
    z-index: 100

    cursor: pointer

    &:hover
        background-color: rgba($red, .8)

    > p
        font-size: 1.3rem
        color: $white